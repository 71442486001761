import { Listbox } from '@headlessui/react'
import { useMemo } from 'react'
import clsx from 'clsx'

import { ChevronDownIcon, WarningIcon } from '../../assets/icons'

interface Props {
	name: string
	label: string
	placeholder: string
	data: string[]
	favoriteData?: string[]
	value?: string
	error?: string
	onChange: (item: string) => void
	selectRef?: React.RefObject<HTMLSelectElement>
}

interface LetterGroup {
	letter: string
	items: string[]
}

function Dropdown({
	name,
	data,
	favoriteData,
	value,
	onChange,
	label,
	placeholder,
	error,
	selectRef,
}: Props) {
	function groupByFirstLetter(data: string[]) {
		const groups = data.reduce((acc: LetterGroup[], currentItem) => {
			const firstLetter = currentItem.slice(0, 1).toUpperCase()
			const section = acc.find((item) => item.letter === firstLetter)
			if (section) {
				section.items.push(currentItem)
			} else {
				acc.push({
					letter: firstLetter,
					items: [currentItem],
				})
			}
			return acc
		}, [])

		return groups.sort((a, b) => (a.letter > b.letter ? 1 : -1))
	}

	const groupedByFirstLetter = useMemo(() => {
		if (favoriteData && favoriteData.length > 0) {
			const favoriteSection = {
				letter: 'Meest gekozen',
				items: favoriteData,
			}

			return [favoriteSection, ...groupByFirstLetter(data)]
		}

		return groupByFirstLetter(data)
	}, [data])

	function renderOption(item: string) {
		return (
			<Listbox.Option
				key={item}
				value={item}
				className="rounded-md cursor-pointer"
			>
				{({ selected, active }) => (
					<div
						className={clsx(
							'px-4 py-2',
							selected && 'font-semibold',
							active && 'bg-[rgb(229,229,229)]',
						)}
					>
						{item}
					</div>
				)}
			</Listbox.Option>
		)
	}

	function renderOptions() {
		if (data.length < 10) {
			return (
				<Listbox.Options className="overflow-auto bg-white shadow-xl rounded-md mt-1 absolute z-20 w-full">
					{data.map((item) => renderOption(item))}
				</Listbox.Options>
			)
		}

		return (
			<Listbox.Options className="h-96 overflow-auto bg-white shadow-xl rounded-md mt-1 absolute z-20 w-full">
				{groupedByFirstLetter.map((group) => (
					<div key={group.letter} className="my-2">
						<span className="px-4 text-xl font-semibold">{group.letter}</span>
						<span>{group.items.map((item) => renderOption(item))}</span>
					</div>
				))}
			</Listbox.Options>
		)
	}

	const Options = useMemo(() => renderOptions(), [data])

	const isErrorPresent = typeof error === 'string'

	return (
		<>
			<div className="sr-only">
				<label htmlFor={`${name}_native`} id={`${name}_label`}>
					{label}
				</label>
				<select
					id={`${name}_native`}
					aria-labelledby={`${name}_label`}
					name={name}
					ref={selectRef}
					value={value}
					onChange={(e) => onChange(e.target.value)}
				>
					{data.length < 10
						? data.map((item) => (
								<option key={item} value={item}>
									{item}
								</option>
							))
						: groupedByFirstLetter.map((group) => (
								<optgroup key={group.letter} label={group.letter}>
									{group.items.map((item) => (
										<option key={item} value={item}>
											{item}
										</option>
									))}
								</optgroup>
							))}
				</select>
			</div>

			<Listbox
				as="div"
				name={name}
				value={value}
				onChange={onChange}
				aria-hidden="true"
				tabIndex={-1}
			>
				{({ open }) => (
					<>
						<Listbox.Label
							className={clsx(
								'font-semibold text-base',
								isErrorPresent && 'text-red-900',
							)}
						>
							{label}
						</Listbox.Label>

						<div className="relative">
							<span className="inline-block w-full bg-white mt-2">
								<Listbox.Button
									className={clsx(
										'rounded-md p-3 flex justify-between items-center w-full border bg-white text-sm',
										'focus:outline-none focus:ring-4 focus:ring-indigo-blue-500',
										isErrorPresent && 'border-red-900',
										!isErrorPresent && 'border-gray-500',
									)}
									tabIndex={-1}
								>
									{value ? (
										<span className="text-text">{value}</span>
									) : (
										<span
											className={clsx(
												isErrorPresent && 'text-red-800',
												!isErrorPresent && 'text-placeholder',
											)}
										>
											{placeholder}
										</span>
									)}

									<ChevronDownIcon
										className={clsx(
											open && 'opacity-50',
											isErrorPresent && 'text-red-800',
										)}
										color="currentColor"
									/>
								</Listbox.Button>
							</span>

							{Options}
						</div>
					</>
				)}
			</Listbox>

			{isErrorPresent && (
				<span
					id={`${name}-error`}
					className="grid grid-cols-[32px_1fr] text-sm text-red-900 mt-2 overflow-hidden w-full"
				>
					<WarningIcon
						className="text-red-900 inline h-[24px] w-[24px] mx-1"
						color="currentColor"
						aria-hidden
					/>

					<span className="block min-h-[24px] leading-6">{error}</span>
				</span>
			)}
		</>
	)
}

export default Dropdown
