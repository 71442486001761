import { useForm } from 'react-hook-form'
import { Button } from '../components'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { getCookieValue, setCookieValue } from '../utils/cookies'

type FormValues = {
  givenPassword: string
}

function simpleHash(stringToHash: string): string {
  let hash = 0
  for (let i = 0; i < stringToHash.length; i++) {
    hash = ((hash << 5) - hash + stringToHash.charCodeAt(i)) | 0
  }
  return (hash >>> 0).toString(36)
}

const PasswordProtected = () => {
  const { handleSubmit, register } = useForm<FormValues>()
  const navigate = useNavigate()
  const authenticated = getCookieValue('authenticated')

  const onSubmit = (data: FormValues) => {
    if (simpleHash(data.givenPassword) === process.env.REACT_APP_PASSWORD_HASH) {
      setCookieValue('authenticated', 'true')
      navigate('/start')
    }

    // debug tool
    // console.log('submitted data:', data.givenPassword, simpleHash(data.givenPassword))
  }

  useEffect(() => {
    if (authenticated) {
      navigate('/start')
    }
  }, [authenticated])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="min-h-screen flex items-center justify-center">
        <div className="pb-12">
          <label className="block text-text mb-2" htmlFor="givenPassword">
            Password:
          </label>
          <input
            id="givenPassword"
            type="password"
            autoSave="off"
            autoComplete="off"
            {...register('givenPassword')}
            className="px-2 py-3 w-44 mb-4 border rounded-sm"
          />
          <Button title="Login" />
        </div>
      </div>
    </form>
  )
}

export default PasswordProtected
