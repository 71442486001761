import { useFormContext, UseFormGetValues } from 'react-hook-form'
import { useEffect, useRef } from 'react'

import Dropdown from '../../components/selectbox/Dropdown'
import {
	EnergyDataFormFields,
	EnergyDataFormSupplierDataFields,
} from './energyDataFormSchema'
import { getPlaceholderForProvider } from '../../utils/customer-number-utils'
import AddSecondaryFormInfoText from './AddSecondaryFormInfoText'
import { TextInputControlled } from '../../components/text-input/TextInputControlled'
import { tContext } from '../../utils/cmsTranslations'
import { InfoTextTranslated } from '../../components/text-types/InfoText'
import { EnergyType } from '../../types/api'
import {
	convertEnumToText,
	convertTextToEnum,
	getInverseEnergyType,
} from '../../utils/energyUtil'
import { MarkdownText } from '../../components'

const t = tContext('energy.input')

interface EnergyDataFormProps {
	isPrimary: boolean
	isBothActive: boolean
	contractHolders: string[]
	energyProviders: string[]
	favoriteEnergyProviders: string[]
	addSecondaryEnergyData?: () => void
}

export default function EnergyDataFormInnerForm({
	isPrimary,
	isBothActive,
	contractHolders,
	favoriteEnergyProviders,
	energyProviders,
	addSecondaryEnergyData,
}: EnergyDataFormProps) {
	const {
		register,
		getValues,
		setValue,
		formState: { errors },
	} = useFormContext<EnergyDataFormFields>()

	const selectRef = useRef<HTMLSelectElement>(null)

	const getInputName = (
		name: keyof EnergyDataFormSupplierDataFields
	): UseFormGetValues<EnergyDataFormFields>['arguments'] =>
		`${isPrimary ? 'primary' : 'secondary'}.${name}`

	const getError = (name: keyof EnergyDataFormSupplierDataFields) =>
		errors[isPrimary ? 'primary' : 'secondary']?.[name]?.message

	useEffect(() => {
		if (!isPrimary && selectRef.current) {
			selectRef.current.focus()
		}
	}, [isPrimary, selectRef])

	return (
		<div className="w-3/4 mb-8">
			<div className="mb-8">
				{isBothActive && (
					<div className="mb-8">
						<Dropdown
							name={getInputName('energyType')}
							label="Energie type"
							selectRef={selectRef}
							placeholder="Selecteer energie type"
							value={convertEnumToText(getValues(getInputName('energyType')))}
							data={[
								convertEnumToText(EnergyType.ELEKTRA),
								convertEnumToText(EnergyType.GAS_OF_WARMTE),
							]}
							onChange={(value: string) => {
								const newEnergyType = convertTextToEnum(value)

								setValue(getInputName('energyType'), newEnergyType, {
									shouldValidate: true,
								})

								setValue(
									`${isPrimary ? 'secondary' : 'primary'}.energyType`,
									getInverseEnergyType(newEnergyType),
									{ shouldValidate: true }
								)
							}}
						/>
					</div>
				)}

				<div className="mb-2">
					<Dropdown
						name={getInputName('supplier')}
						label={t('inputSupplier.label')}
						placeholder={t('inputSupplier.placeholder')}
						value={getValues(getInputName('supplier'))}
						favoriteData={favoriteEnergyProviders}
						data={energyProviders}
						onChange={(value: string) => {
							setValue(getInputName('supplier'), value, {
								shouldValidate: true,
							})

							// we need to also 'tell the validator' the clientNumber field can re-check its validation
							const currentClientNumber = getValues(
								getInputName('clientNumber')
							)
							if (currentClientNumber) {
								setValue(getInputName('clientNumber'), currentClientNumber, {
									shouldValidate: true,
								})
							}
						}}
						error={getError('supplier')}
					/>
				</div>

				{!isBothActive && (
					<input
						type="hidden"
						name={getInputName('energyType')}
						value={getValues(getInputName('energyType'))}
					/>
				)}
			</div>

			<div className="mb-8">
				<div className="mb-2">
					<Dropdown
						name={getInputName('contractHolderName')}
						label={t('inputContractHolder.label')}
						placeholder={t('inputContractHolder.placeholder')}
						value={getValues(getInputName('contractHolderName'))}
						data={contractHolders}
						onChange={(value) =>
							setValue(getInputName('contractHolderName'), value, {
								shouldValidate: true,
							})
						}
						error={getError('contractHolderName')}
					/>
				</div>

				{isPrimary && <InfoTextTranslated textId="inputContractHolder" />}
			</div>

			<div className="mb-8">
				<TextInputControlled
					{...register(getInputName('clientNumber'))}
					aria-required={true}
					title={t('inputCustomerNumber.label')}
					placeholder={getPlaceholderForProvider(
						energyProviders,
						getValues(getInputName('supplier')),
						getValues(getInputName('clientNumber'))
					)}
					hint={getError('clientNumber') || false}
					hintVariant={getError('clientNumber') ? 'error' : 'warning'}
					classNameOverride="mb-2"
				/>

				{isPrimary && <InfoTextTranslated textId="inputCustomerNumber" />}
			</div>

			<div className="mb-8">
				<TextInputControlled
					{...register(getInputName('actualAdvance'))}
					aria-required={true}
					title={t('inputActualAdvance.label')}
					placeholder={t('inputActualAdvance.placeholder')}
					isMoney={true}
					hint={getError('actualAdvance')}
					hintVariant={getError('actualAdvance') ? 'error' : 'warning'}
					classNameOverride="mb-2"
				/>

				{isPrimary && <InfoTextTranslated textId="inputActualAdvance" />}
			</div>

			<div className="mb-12">
				<TextInputControlled
					{...register(getInputName('advanceAdvice'))}
					title={t('inputAdviceAdvance.label')}
					placeholder={t('inputAdviceAdvance.placeholder')}
					isMoney={true}
					hint={getError('advanceAdvice')}
					hintVariant={getError('advanceAdvice') ? 'error' : 'warning'}
					classNameOverride="mb-2"
				/>

				{isPrimary && <InfoTextTranslated textId="inputAdviceAdvance" />}
			</div>

			{isPrimary && (
				<MarkdownText text={t('secondSupplierModal.text')} className="mb-8" />
			)}

			{isPrimary && !!addSecondaryEnergyData && (
				<AddSecondaryFormInfoText onClick={addSecondaryEnergyData} />
			)}
		</div>
	)
}
