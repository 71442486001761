import { useTitle } from '../../hooks/useTitle'
import { IncomeIntro } from '../../pageComponents/Income/IncomeIntro'
import RedirectFirstPage from '../../routes/RedirectFirstPage'
import { AvailableStep, useSetActiveStep } from '../../utils/stepUtil'

export default function IncomeIntroPage() {
	useTitle('Inkomen introductie')
	useSetActiveStep(AvailableStep.incomeIntro)

	return (
		<RedirectFirstPage>
			<IncomeIntro />
		</RedirectFirstPage>
	)
}
