import { useEffect } from 'react'
import { AvailableStep } from './stepUtil'
import {
	browserName,
	BrowserTypes,
	browserVersion,
	osName,
	osVersion,
} from 'react-device-detect'
import { IS_DEVELOPMENT_ENV } from './utils'

const getUrl = (env?: string) => {
	const BASE_URL = 'https://logging.service.iwize.nl/'

	switch (env) {
		case 'acct':
			return `${BASE_URL}env/acct/api/v1`
		case 'test':
			return `${BASE_URL}env/test/api/v1`
		case 'production':
			return `${BASE_URL}api/v1`

		default:
			return `${BASE_URL}env/dev/api/v1`
	}
}

export const getBrowserName = () => {
	switch (browserName) {
		case BrowserTypes.Chrome:
			return 'chrome'
		case BrowserTypes.Edge:
			return 'edge'
		case BrowserTypes.Firefox:
			return 'firefox'
		case BrowserTypes.Safari:
			return 'safari'
		case BrowserTypes.MobileSafari:
			return 'mobile-safari'
		case BrowserTypes.Opera:
			return 'opera'
		case BrowserTypes.Chromium:
			return 'chromium'
	}

	return String(browserName).toLowerCase()
}

type NagivationSubStep =
	| 'personen-selectie'
	| 'inkomen-overeenkomst'
	| 'persoon-overzicht'
	| 'controleer-inkomen'
	| 'inkomens-ophalen'
	| 'inkomen-verzameld'
	| 'gegevens-ophalen'

export const getNagivationEnum = (
	step: AvailableStep,
	substep?: NagivationSubStep
): string => {
	switch (step) {
		case AvailableStep.registrationStart:
			return 'Registratie - Uitleg'

		case AvailableStep.registrationEmail:
			return 'Registratie - Email'

		case AvailableStep.registrationValidateEmail:
			return 'Registratie - Email verificatie'

		case AvailableStep.registrationExtraDetails:
			return 'Registratie - Extra gegevens gemachtigde'

		case AvailableStep.registrationPhoneNumber:
			return 'Registratie - Telefoonnummer'

		case AvailableStep.registrationValidatePhoneNumber:
			return 'Registratie - Telefoonnummer verificatie'

		case AvailableStep.registrationExtension:
			return 'Registratie - Extensie installatie'

		case AvailableStep.registrationAgreement:
			return 'Registratie - Overeenkomst'

		case AvailableStep.householdIntro:
			return 'Huishouden - Uitleg'

		case AvailableStep.inputHousehold:
			if (substep === 'gegevens-ophalen') {
				return 'Generiek - Gegevens ophalen'
			}

			return 'Huishouden - Toeslagen ophalen'

		case AvailableStep.review:
			if (substep === 'gegevens-ophalen') {
				return 'Generiek - Gegevens ophalen'
			}

			if (substep === 'personen-selectie') {
				return 'Huishouden - Personen selectie'
			}

			if (substep === 'inkomen-overeenkomst') {
				return 'Inkomen - Overeenkomst'
			}

			if (substep === 'persoon-overzicht') {
				return 'Inkomen - Persoon overzicht'
			}

			if (substep === 'controleer-inkomen') {
				return 'Inkomen - Controleer inkomen'
			}

			if (substep === 'inkomen-verzameld') {
				return 'Inkomen - Inkomen verzameld'
			}

			if (substep === 'inkomens-ophalen') {
				return 'Inkomen - Inkomens ophalen'
			}

			return 'Inkomen - Persoon overzicht'

		case AvailableStep.incomeIntro:
			return 'Inkomen - Uitleg'

		case AvailableStep.people:
			return 'Huishouden - Verzamelde gegevens'

		case AvailableStep.energyIntro:
			return 'Energie - Uitleg'

		case AvailableStep.energyData:
			return 'Energie - Formulier'

		case AvailableStep.overview:
			return 'Versturen - Controle'

		case AvailableStep.finish:
			return 'Versturen - Resultaat'
	}

	console.log('getNagivationEnum - step not found', step)
	return 'Blokkade - Bericht'

	// webapp doesn't have this case?
	// "Landingspagina"
	// "Blokkade - Bericht"
	// "Generiek - Gegevens ophalen"
	// "Generiek - Helpvenster"
	// "Generiek - Overeenkomstvenster"
}

export const fireLogNavigationEvent = (
	step: AvailableStep,
	ticket: string,
	substep?: NagivationSubStep
) => {
	if (!ticket) return

	try {
		const timestamp = new Date().toISOString()
		const env = process.env.REACT_APP_ENV
		const body = {
			timestamp,
			origin: 'noodfonds-web',
			type: 'web-navigation',
			path: getNagivationEnum(step, substep),
			browser: getBrowserName(),
			browserVersion: browserVersion,
			platform: osName,
			platformVersion: osVersion,
		}

		if (IS_DEVELOPMENT_ENV) {
			console.info('[logNavigation debug]', body.path)
		}

		fetch(`${getUrl(env)}/log/event`, {
			method: 'POST',
			cache: 'no-cache',
			headers: {
				'Content-Type': 'application/json',
				Ticket: ticket,
			},
			body: JSON.stringify(body),
		})
	} catch (error: any) {
		console.error('[logNavigation error]', error)
	}
}

export const useLogNavigation = (
	step: AvailableStep,
	ticket: string,
	substep?: NagivationSubStep
) => {
	useEffect(() => {
		fireLogNavigationEvent(step, ticket, substep)
	}, [step, substep, ticket])
}
