import React from 'react'
import { CheckRoundedIcon } from '../../assets/icons'
import clsx from 'clsx'

type Props = {
	line1: string
	line2: string
	classNameOverride?: string
	isWide?: boolean
}

export const CompletedText: React.FC<Props> = ({
	line1,
	line2,
	classNameOverride,
	isWide = false,
}) => {
	return (
		<div
			className={clsx(
				`flex gap-3 flex-row items-center justify-start py-3 px-4 rounded-[0.75rem] bg-green-600 ${classNameOverride}`,
				{
					'w-[340px]': !isWide,
					'w-full': isWide,
				}
			)}
		>
			<CheckRoundedIcon className="shrink-0" aria-hidden="true" />

			<div className="flex flex-col items-start justify-center">
				<p className="text-base font-semibold text-white">{line1}</p>
				<p className="text-[14px] font-semibold text-white">{line2}</p>
			</div>
		</div>
	)
}
