import { useTitle } from '../../hooks/useTitle'
import { EnergyIntro } from '../../pageComponents/EnergyIntro/EnergyIntro'
import { AvailableStep, useSetActiveStep } from '../../utils/stepUtil'

export default function EnergyIntroPage() {
	useTitle('Energiegegevens introductie')
	useSetActiveStep(AvailableStep.energyIntro)

	return <EnergyIntro />
}
