import {
	isMobile,
	isChrome,
	isEdgeChromium,
	isFirefox,
} from 'react-device-detect'
import { Aqopi } from '@iwize-aqopi/aqopi-types'
import { HouseholdData, IncomeData, IncomeUI, PersonState } from '../types/api'
import { formatDutchDate } from './formatDate'
import {
	crossCheckData,
	formatProcessableIncomeData,
	parseData,
	processIncome,
} from './noodfonds-service-helpers'
import { http } from './http'
import { ProcessFailureType } from './failures'
import { logFunc } from './logging-service'
import { isFeatureFlagEnabled } from './featureFlags'

const headers = {
	'Content-Type': 'application/json',
}

const sendConfirmationEmail = async (email: string) => {
	try {
		const confirmation = await http.fetch(`${process.env.REACT_APP_ENERGY_SERVICE}/send-confirmation`, {
			method: 'POST',
			headers,
			body: JSON.stringify({
				email,
			}),
		})

		if (!confirmation.ok) {
			throw new Error(
				`Sending confirmation failed, with status ${confirmation.status} ${confirmation.statusText}`
			)
		}
		return confirmation
	} catch (err) {
		console.error(err)
		return null
	}
}

const checkBrowserCompatibility = () => {
	if ((isChrome || isEdgeChromium || isFirefox) && !isMobile) {
		return true
	}

	return false
}

async function processHousehold(
	parseResult: Aqopi.Envelope,
	ticket: string
): Promise<HouseholdData | undefined> {
	try {
		const response = await http.fetch(`${process.env.REACT_APP_ENERGY_SERVICE}/process/household`, {
			method: 'POST',
			headers: { ...headers, ticket },
			body: JSON.stringify(parseResult),
		})

		const json = response.json()

		if (!response.ok) {
			console.log(json)
			throw new Error(
				`Processing household data failed, with status ${response.status}`
			)
		}

		return json
	} catch (error: any) {
		console.error(error)
	}
}

async function authenticate(passcode: string, email: string): Promise<string> {
	try {
		const response = await http.fetch(`${process.env.REACT_APP_ENERGY_SERVICE}/authenticate`, {
			method: 'POST',
			headers: headers,
			body: JSON.stringify({
				email,
				passcode,
			}),
		})

		const json = await response.json()

		if (!response.ok) {
			throw new Error(
				`Authentication failed, with status ${response.status} ${json.error.message}`
			)
		}
		return json.ticket
	} catch {
		return ''
	}
}

const finalizeToeslagenQuery = async (
	blob: any,
	ticket: string,
	household: HouseholdData
): Promise<HouseholdData> => {
	const result = await parseData({ data: blob.data }, ticket)
	const processedHousehold = result && (await processHousehold(result, ticket))

	if (processedHousehold) {
		household = processedHousehold
		household.mijnHuishoudenParseResult = result
		household.personen = household.personen.map((d, i) => ({
			...d,
			isInitiator: i === 0,
			isContracthouder: false,
			state: PersonState.TODO,
		}))
	}

	return household
}

const finalizeInkomenQuery = async (
	allIncomeData: any,
	ticket: string,
	personId: string,
	householdParseResult?: Record<any, any>
): Promise<IncomeData | null> => {
	let parseResults = await Promise.all(
		allIncomeData.map((blob: any) => parseData(blob, ticket))
	)

	if (parseResults.some(({ queryResult }) => queryResult.metadata.parserId === 'mbd-verzamelinkomens' && !queryResult.data?.volledigeNaam)) {
		throw ProcessFailureType.ParsedDataIncomplete
	}

	parseResults = parseResults.filter(({ queryResult }) => {
		if (
			queryResult?.metadata?.parserId === 'uwv-verzekeringsbericht' &&
			!queryResult?.data.geboorteDatum
		) {
			return false
		}
		return true
	})

	const dataForLog = parseResults.find(({ queryResult }) => queryResult?.metadata?.parserId === 'uwv-verzekeringsbericht')?.queryResult?.data
	logFunc().log('uwv-info', `name: ${dataForLog?.naam}; geb: ${dataForLog?.geboorteDatum?.substring(0, 2)}; werkgevers: ${dataForLog?.werkgevers?.length}`, ticket)

	const crossChecked = await crossCheckData(parseResults.concat(householdParseResult ?? []), ticket)

	if (crossChecked.data.validationStatus === 'invalid' && isFeatureFlagEnabled('submit_for_review')) {
		throw ProcessFailureType.CrossCheckNegative
	}

	const processableParseResults = formatProcessableIncomeData(parseResults)
	const processedIncome = await processIncome(
		personId,
		ticket,
		processableParseResults
	)

	if (processedIncome) {
		if (processedIncome.uwvData) {
			processedIncome.uwvData.blob = allIncomeData.find((blob: Aqopi.Blob) =>
				blob.data.parserId.startsWith('uwv')
			)
		}
		processedIncome.crossCheckResult = crossChecked
	}

	return processedIncome
}

const incomeDataToViewModel = (incomeData: IncomeData): IncomeUI => {
	return {
		currentIncomes: incomeData?.uwvData?.periodes.map((p) => [
			formatDutchDate(p.startdatum),
			formatDutchDate(p.einddatum),
			p.bedrag,
		]),
		//grossIncome: incomeData?.ibriData?.bedrag,
		grossIncome: incomeData?.klantbeeldData?.bedrag,
		sivIncomes: incomeData?.viaData?.sivInkomens.map((s) => [
			s.sivCode,
			s.bedrag,
		]),
		birthDate: incomeData?.uwvData?.geboortedatum
			? formatDutchDate(incomeData.uwvData.geboortedatum)
			: undefined,
	}
}

export {
	finalizeInkomenQuery,
	finalizeToeslagenQuery,
	sendConfirmationEmail,
	checkBrowserCompatibility,
	processHousehold,
	authenticate,
	incomeDataToViewModel,
}
