import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { AvailableStep, useSetActiveStep } from '../../utils/stepUtil'
import { AppContext } from '../../context/app-context'
import { getCookieValue } from '../../utils/cookies'

const isTestEnviroment = ['development', 'test', 'acct'].includes(
	process.env.REACT_APP_ENV || '',
)

// this page only exists to redirect to the first page of the registration flow
const FirstPage = () => {
	const navigate = useNavigate()

	useSetActiveStep(AvailableStep.registrationStart)

	const { setIsGemachtigd, setWasAutomaticallySignedOut } =
		useContext(AppContext)

	useEffect(() => {
		const authenticationCookie = getCookieValue('authenticated')

		if (isTestEnviroment && !authenticationCookie) {
			navigate('/login')
		} else {
			const urlParams = new URLSearchParams(window.location.search)

			if (
				process.env.REACT_APP_GEMACHTIGD_ENABLED === 'true' &&
				urlParams.get('machtiging') === 'true'
			) {
				setIsGemachtigd(true)
			} else {
				setIsGemachtigd(false)
			}

			if (urlParams.get('timeoutMelding') === 'true') {
				setWasAutomaticallySignedOut(true)
			}

			if (urlParams.get('slow') === 'true') {
				try {
					window.localStorage.setItem('slow', 'true')
				} catch (e) {
					console.error(e, 'localstorage not available')
				}
			}

			navigate('/start')
		}
	}, [])

	return null
}

export default FirstPage
