import React from 'react'
import { useForm } from 'react-hook-form'
import { string, object, ObjectSchema } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { ChevronRightIcon } from '../../assets/icons'
import { Button, Title, Notice, InfoBoxText } from '../../components'
import { useTitle } from '../../hooks/useTitle'
import { tContext } from '../../utils/cmsTranslations'
import { TextInputControlled } from '../../components/text-input/TextInputControlled'
import { AvailableStep, useSetActiveStep } from '../../utils/stepUtil'
import { isConsideredDutchNumber } from '../../utils/regexpHelpers'
import { IS_DEVELOPMENT_ENV } from '../../utils/utils'

const t = tContext('registration.phoneNumber')
const tValidation = tContext('validation')

type Props = {
	onSubmit: (phoneNumber: string) => Promise<any>
}

type FormValues = {
	phoneNumber: string
}

const getSanitizedPhoneNumber = (phoneNumber: string) =>
	phoneNumber.replace(/\s|-/g, '')

const phoneNumberSchema: ObjectSchema<FormValues> = object({
	phoneNumber: string()
		.required(tValidation('validationPhoneNumberRequired'))
		.test('len', tValidation('validationPhoneNumberInvalid'), (val) => {
			if (typeof val !== 'string') return false

			const sanitized = getSanitizedPhoneNumber(val)
			return (
				sanitized.length >= 10 &&
				sanitized.length <= 15 &&
				isConsideredDutchNumber(sanitized)
			)
		}),
})

export const Substep_3: React.FC<Props> = ({ onSubmit }) => {
	useTitle('Telefoonnummer')
	useSetActiveStep(AvailableStep.registrationPhoneNumber)

	const { handleSubmit, formState, register, setError } = useForm<FormValues>({
		resolver: yupResolver(phoneNumberSchema),
		defaultValues: IS_DEVELOPMENT_ENV ? { phoneNumber: '+31612345678' } : {},
	})

	function onSubmitInternal(fields: FormValues) {
		onSubmit(getSanitizedPhoneNumber(fields.phoneNumber)).catch(() => {
			setError('root', {
				type: 'serverError',
				message: t('serverError'),
			})
		})
	}

	return (
		<div className="flex flex-col items-start w-full h-full">
			<form onSubmit={handleSubmit(onSubmitInternal)}>
				<Title classNameOverride="mb-6">{t('title')}</Title>

				{formState.errors.root?.type === 'serverError' && (
					<Notice
						noticeType="error"
						classNameOverride="mb-8"
						text={formState.errors.root?.message}
					/>
				)}

				<InfoBoxText
					title={t('notice.title')}
					text={t('notice.text')}
					classNameOverride="mb-8"
				/>

				<div className="w-full md:w-1/2 mb-6">
					<TextInputControlled
						{...register('phoneNumber')}
						type="tel"
						aria-required={true}
						autoComplete="off"
						title={t('inputPhoneNumber.label')}
						placeholder={t('inputPhoneNumber.placeholder')}
						hint={
							formState.errors.phoneNumber
								? formState.errors.phoneNumber.message
								: undefined
						}
						hintVariant={'error'}
					/>
				</div>

				<Button
					data-testid="submit-button"
					title={t('buttonSubmit.title')}
					icon={<ChevronRightIcon />}
					buttonProps={{ type: 'submit' }}
				/>
			</form>
		</div>
	)
}
