export class TokenError extends Error {}

type FetchReturn<T = any> = Promise<{ data: T; ok: boolean }>

const doFetch = async (
	path: string,
	body: Record<string, any>,
	headers?: Record<string, string>,
	parseJson = false,
): FetchReturn => {
	const response = await fetch(process.env.REACT_APP_ENERGY_SERVICE + path, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			...headers,
		},
		body: JSON.stringify(body),
	})

	if (response.status === 400) {
		const { error } = await response.json()

		return Promise.reject(
			error?.name === 'InvalidOTPTokenError'
				? new TokenError('invalid otp token')
				: new Error('invalid parameter'),
		)
	}

	if (!response.ok) {
		return Promise.reject()
	}

	return Promise.resolve({
		ok: true,
		data: parseJson ? await response.json() : {},
	})
}

export const sendOtpTokenRequest = async (email: string): FetchReturn => {
	return doFetch('/request-email-verification', { email })
}

export const verifyEmailRequest = async (
	email: string,
	code: string,
): Promise<string> => {
	try {
		const tokenCheck = await fetch(
			process.env.REACT_APP_ENERGY_SERVICE + '/verify-email',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ email: email, token: code }),
			},
		)

		const response = await tokenCheck.json()

		if (tokenCheck.status === 400) {
			const { error } = response

			return Promise.reject(
				error?.name === 'InvalidOTPTokenError'
					? new TokenError('invalid otp token')
					: new Error('invalid parameter'),
			)
		}

		if (!tokenCheck.ok) {
			return Promise.reject()
		}

		const ticket = response.ticket
		return ticket
	} catch {
		return Promise.reject(new Error('invalid parameter'))
	}
}

// the api only accepts "+31" (NL) followed by 9 digits as one string, so we convert any input to that format
function convertPhoneNumberForApi(phoneNumber: string): string {
	/**
	 * Regular expression pattern to split a phone number into groups.
	 * The pattern matches phone numbers in the format:
	 * - 0 to 2 leading zeros (optional)
	 * - an optional '+' sign followed by '31' (optional)
	 * - 9 digits
	 *
	 * Example inputs:
	 * - 0031612345678
	 * - +31612345678
	 * - 0612345678
	 * - 31612345678
	 */
	const groupPhoneNumber = /(0{0,2})?(\+?31)?([0-9]{9})/
	return phoneNumber.replace(groupPhoneNumber, '+31$3')
}

export const requestPhoneVerificationRequest = async (
	email: string,
	phoneNumberRaw: string,
	ticket?: string,
): FetchReturn<{ verified: boolean }> => {
	return doFetch(
		'/request-phone-verification',
		{ email, phoneNumber: convertPhoneNumberForApi(phoneNumberRaw) },
		ticket ? { ticket } : {},
		true,
	)
}

export const verifyPhoneRequest = async (
	email: string,
	phoneNumberRaw: string,
	code: string,
	ticket?: string,
): FetchReturn => {
	return doFetch(
		'/verify-phone',
		{
			email,
			phoneNumber: convertPhoneNumberForApi(phoneNumberRaw),
			token: code,
		},
		ticket ? { ticket } : {},
	)
}
