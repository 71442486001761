import { useTitle } from '../../hooks/useTitle'
import { HouseholdIntro } from '../../pageComponents/Household/HouseholdIntro'
import RedirectFirstPage from '../../routes/RedirectFirstPage'
import { AvailableStep, useSetActiveStep } from '../../utils/stepUtil'

export default function HouseholdIntroPage() {
	useTitle('Huishouden introductie')
	useSetActiveStep(AvailableStep.householdIntro)

	return (
		<RedirectFirstPage>
			<HouseholdIntro />
		</RedirectFirstPage>
	)
}
