import { Aqopi } from '@iwize-aqopi/aqopi-types'
import { ProcessFailureType, QueryFailureType } from '../utils/failures'

declare global {
	interface Window {
		pdfjsLib: any
		brondata: Brondata
		aqopi: GlobalAqopi
		'aqopi.parser': AqopiParser
	}
}

interface GlobalAqopi {
	'pdf.summary': {
		generatePdfBlob: any
	}
}

export interface Brondata {
	'extension.api': {
		extensionApi: ExtensionApi
		install(): boolean
	}
}

export enum ConfigEndpoint {
	STUB = 'IWIZE-STUB',
	ACCEPTANCE = 'IWIZE-ACCEPTANCE',
}

export enum ConfigId {
	TOESLAGEN = 'mts-nfe-ext',
	BELASTINGDIENST = 'mbd-nfe-ext',
	UWV = 'uwv-nfe-ext',
	TOESLAGEN_GEMACHTIGD = 'mts-nfe-2-ext',
	BELASTINGDIENST_GEMACHTIGD = 'mbd-nfe-2-ext',
	BELASTINGDIENST_VIA_GEMACHTIGD = 'mbd-nfe-3-ext',
	UWV_GEMACHTIGD = 'uwv-nfe-2-ext',
}

export enum QueryStatus {
	Untouched = 'UNTOUCHED',
	Success = 'SUCCESS',
	Active = 'ACTIVE',
	Failed = 'Failed',
}

export type MTSData = 'huishouden'
export type BelastingdienstData =
	| 'via'
	| 'klantbeeld'
	| 'inkomensverklaring-2021-html'
export type UWVData = 'verzekeringsbericht'

export type DataId = MTSData | BelastingdienstData | UWVData

export type Config = {
	id: ConfigId
	data: DataId[]
	status: QueryStatus
	failureType?: QueryFailureType | ProcessFailureType
}

export interface ExtensionApi {
	cancelCurrentQuery(): void
	getCurrentConfigId(): string
	hasNext(): boolean
	init(
		config: any,
		parserFactory: any,
		processParser: any,
		uninstallSuppress?: boolean,
		debug?: boolean,
		options?: any
	): Promise<boolean>
	install(): Promise<void>
	next(): void
	skip(): Promise<boolean>
	startQuerySession(
		configs: Pick<Config, 'id' | 'data'>[],
		jwt?: string
	): AqopiSession
	uninstall(): void
}

export interface AqopiSession {
	onStartQuery(onStartQueryFunc: (configId: ConfigId) => void): AqopiSession
	onQueryProgress: (
		onQueryProgressFunc: (progress: number) => void
	) => AqopiSession
	onAuthenticate: (
		onAuthenticateFunc: (callback: () => void) => void
	) => AqopiSession
	onAuthenticated: (
		onAuthenticatedFunc: (callback: () => void) => void
	) => AqopiSession
	onQuerySuccess: (onQuerySuccessFunc: (response: any) => void) => AqopiSession
	onQueryFailure: (
		onQueryFailureFunc: (failureType: QueryFailureType) => void
	) => AqopiSession
	onDone: (onDoneFunc: (result: Result) => void) => AqopiSession
}

export interface AqopiParser {
	prepare: { ParserFactory: any }
	'fin-advies.process': { getParser: () => any }
}

export type Blob = Aqopi.Blob
export type Summary = Aqopi.Summary

export interface ParseResult {
	data: {
		summary: {
			data: Summary
		}
	}
}

export type Result = { parseResult: ParseResult; signature: string }
