import { useContext, useMemo } from 'react'

import { AppContext } from '../../context/app-context'
import RedirectFirstPage from '../../routes/RedirectFirstPage'
import { SinglePerson } from '../../pageComponents/Review/SinglePerson'
import { MultiplePeople } from '../../pageComponents/Review/MultiplePeople'
import { AuthorizedSinglePerson } from '../../pageComponents/Review/Authorized/AuthorizedSinglePerson'
import { AuthorizedMultiplePeople } from '../../pageComponents/Review/Authorized/AuthorizedMultiplePeople'

enum ViewType {
	SINGLE,
	MULTI,
	SINGLE_AUTHORIZED,
	MULTI_AUTHORIZED,
}

const ReviewComponent = ({ viewType }: { viewType: ViewType }) => {
	switch (viewType) {
		case ViewType.SINGLE:
			return <SinglePerson />
		case ViewType.MULTI:
			return <MultiplePeople />
		case ViewType.SINGLE_AUTHORIZED:
			return <AuthorizedSinglePerson />
		case ViewType.MULTI_AUTHORIZED:
			return <AuthorizedMultiplePeople />
	}
}

export default function ReviewPage() {
	const { householdData, isGemachtigd } = useContext(AppContext)

	const viewType = useMemo(() => {
		const people = householdData.personen
		const isMultiplePeople = people && people.length > 1

		if (isGemachtigd) {
			return isMultiplePeople
				? ViewType.MULTI_AUTHORIZED
				: ViewType.SINGLE_AUTHORIZED
		}

		return isMultiplePeople ? ViewType.MULTI : ViewType.SINGLE
	}, [])

	return (
		<RedirectFirstPage>
			<div className="w-full">
				<ReviewComponent viewType={viewType} />
			</div>
		</RedirectFirstPage>
	)
}
