import { Dispatch, FC, SetStateAction, useState } from 'react'
import Dialog from '../modal/Dialog'
import { HelpModalContent } from './HelpModalContent'
import { tContext } from '../../utils/cmsTranslations'
import { RadioGroup } from '@headlessui/react'
import { RadioButton } from '../RadioButton/RadioButton'

const t = tContext('helpModal')

interface HelpModalProps {
	open: boolean
	setOpen: Dispatch<SetStateAction<boolean>>
	isResult?: boolean
}

const HelpModal: FC<HelpModalProps> = ({ open, setOpen, isResult }) => {
	const [selectedOption, setSelectedOption] = useState<string>('')

	const items: {
		label: string
		subText: string
		button?: string
		showSupportCode: boolean
	}[] = [
		...(isResult
			? [
					{
						label: t('result.title'),
						subText: t('result.text'),
						button: t('result.email'),
						showSupportCode: false,
					},
				]
			: [
					{
						label: t('question.title'),
						subText: t('question.text'),
						showSupportCode: false,
					},
				]),
		{
			label: t('help.title'),
			subText: t('help.text'),
			button: t('help.phoneNumber'),
			showSupportCode: true,
		},
	]

	return (
		<Dialog title="Help" open={open} setOpen={setOpen} showCloseButton>
			<p className="text-sm font-normal text-text">{t('text')}</p>
			<fieldset className="my-4">
				<legend className="sr-only">{t('text')}</legend>
				<RadioGroup
					value={selectedOption}
					onChange={setSelectedOption}
					className="my-4"
				>
					{items.map((item) => {
						const checked = selectedOption === item.label
						return (
							<>
								<RadioButton
									id={item.label}
									value={item.label}
									name="helpOption"
									onChange={setSelectedOption}
									text={item.label}
									checked={checked}
								/>
								{checked && (
									<HelpModalContent
										className="ml-10 py-3"
										button={item.button}
										showSupportCode={item.showSupportCode}
										text={item.subText}
									/>
								)}
							</>
						)
					})}
				</RadioGroup>
			</fieldset>
		</Dialog>
	)
}

export default HelpModal
