/* eslint-disable @typescript-eslint/no-unused-vars */
import Markdown, { Components } from 'react-markdown'

const COMPONENTS: Components = {
	p: ({ node, ...props }) => <p className="font-normal mb-4" {...props} />,
	strong: ({ node, ...props }) => (
		<strong className="font-semibold" {...props} />
	),
	ul: ({ node, ...props }) => (
		<ul className="list-disc list-outside pl-6 mb-6" {...props} />
	),
	a: ({ node, children, ...props }) => {
		return (
			<a className="underline" target="_blank" {...props}>
				{children}
			</a>
		)
	},
	h3: ({ node, children, ...props }) => {
		return (
			<h3 className="font-semibold text-md mt-4 mb-2" {...props}>
				{children}
			</h3>
		)
	},
}

export const MarkdownText = ({
	text,
	className,
}: {
	text: string
	className?: string
}) => {
	if (className) {
		return (
			<div className={className}>
				<Markdown skipHtml components={COMPONENTS}>
					{text}
				</Markdown>
			</div>
		)
	}

	return (
		<Markdown skipHtml components={COMPONENTS}>
			{text}
		</Markdown>
	)
}
