import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { ChevronRightIcon } from '../../assets/icons'
import {
  Button,
  InfoBoxText,
  MarkdownText,
  TextLight,
  Title,
} from '../../components'
import AgreementModal from '../../components/Agreement/AgreementModal'
import { UserInfoTable } from '../../components/info-table/UserInfoTable'
import Dialog from '../../components/modal/Dialog'
import { AppContext } from '../../context/app-context'
import { useDisableBackButton } from '../../hooks/useDisableBackButton'
import { useTitle } from '../../hooks/useTitle'
import { IncomeData, IncomeUI, PersonState } from '../../types/api'
import { showFailure } from '../../utils/failures'
import {
  hasUwvData,
  incomeDataToViewModel,
} from '../../utils/noodfonds-service-helpers'
import Query from './Query'
import { tContext, t as tAll } from '../../utils/cmsTranslations'
import { AvailableStep, useSetActiveStep } from '../../utils/stepUtil'
import { AddIncomeForm } from './AddIncomeForm'
import { AllPersonsListing } from './AllPersonsListing'
import { PersonIndicator } from './PersonIndicator'
import { useAqopi } from '../../hooks/useAqopi'
import { IncomeButton } from './IncomeButton'
import { Config, QueryStatus } from '../../types/aqopi'
import { fireLogNavigationEvent } from '../../utils/navigation-logging'
import { getRoute } from '../../utils/routeUtils'

const t = tContext('income')

type Accepted = {
  id: string
  accepted: boolean
}

type ModalType = 'agreement-explanation' | 'agreement' | 'collect'

export const MultiplePeople: React.FC = () => {
  useTitle('Gegevens inkomen en werk')
  useSetActiveStep(AvailableStep.review)
  const { startQuery, configs, result, resetSession } = useAqopi('income')
  useDisableBackButton()
  const {
    householdData,
    setSavedIncomeData,
    setBackStep,
    setHouseholdData,
    ticket,
  } = useContext(AppContext)

  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState<ModalType>('agreement')
  const [viewModel, setViewModel] = useState<IncomeUI | null>(null)
  const [activeConfig, setActiveConfig] = useState<Config>()

  const [manual, setManual] = useState<number>(0)
  const [selectedPersonId, setSelectedPersonId] = useState<string>('')
  const [accepted, setAccepted] = useState<Accepted[]>([])

  const [failureMessage, setFailureMessage] = useState<{
    title: string
    msg: string
  } | null>(null)

  const navigate = useNavigate()

  const onClick = () => {
    navigate(getRoute(AvailableStep.energyIntro), { replace: true })
  }

  useEffect(() => {
    const active = configs.find((c) => c.status === QueryStatus.Active)
    const failed = configs.find((c) => c.status === QueryStatus.Failed)

    if (failed?.id.includes('mbd-') && failed?.failureType) {
      setFailureMessage(showFailure(failed.failureType))
      setShowModal(false)
      resetSession()
    } else {
      setActiveConfig(active)
    }
  }, [configs])

  useEffect(() => {
    if (result) {
      setViewModel(incomeDataToViewModel(result as IncomeData))
    }
  }, [result])

  useEffect(() => {
    setFailureMessage(null)
  }, [selectedPersonId])

  useEffect(() => {
    setAccepted(
      householdData.personen.map((p) => {
        return { id: p.id, accepted: p.isInitiator ? true : false }
      })
    )
  }, [householdData.personen])

  useEffect(() => {
    if (showModal) {
      setBackStep(null)
    }
  }, [showModal])

  const renderName = () => {
    const person = householdData.personen.find(
      (el) => el.id === selectedPersonId
    )
    if (person) {
      return person.volledigeNaam
    } else {
      return ''
    }
  }

  const isAllowedToProceed = (index: number, skip = false) => {
    if (accepted[index].accepted || skip) {
      setModalType('collect')
      setShowModal(true)
      setManual(0)
      resetSession()
    } else {
      setShowModal(true)
      setModalType('agreement-explanation')
    }
  }

  const isAllowedToProceedById = (id: string) => {
    const index = householdData.personen.findIndex((el) => el.id === id)
    isAllowedToProceed(index)
  }

  const acceptAndContinue = () => {
    setAccepted(
      accepted.map((el) =>
        el.id === selectedPersonId ? { ...el, accepted: true } : el
      )
    )
    isAllowedToProceed(
      accepted.findIndex((el) => el.id === selectedPersonId),
      true
    )
  }

  const updateDataAndContinue = (manualIncomeToSet: number | undefined) => {
    const updatedPersons = householdData.personen.map((el) => {
      if (el.id === selectedPersonId) {
        return {
          ...el,
          state: PersonState.SUCCESS,
        }
      } else {
        return el
      }
    })
    setViewModel(null)

    setHouseholdData({
      ...householdData,
      personen: updatedPersons,
    })

    if (result) {
      ; (result as IncomeData).manuallyProvidedIncome =
        !manualIncomeToSet || manualIncomeToSet === 0
          ? undefined
          : +manualIncomeToSet
      setSavedIncomeData((prev) => [...prev, result as IncomeData])
    }

    setShowModal(false)

    fireLogNavigationEvent(AvailableStep.review, ticket, 'inkomen-verzameld')
  }

  const onNavigate = async () => {
    updateDataAndContinue(manual)
  }

  const onSave = (manualIncomeFromForm: string) => {
    const manualIncomeAsNumber = parseFloat(
      manualIncomeFromForm.replace(',', '.')
    )
    setManual(manualIncomeAsNumber)
    setViewModel({ ...viewModel, grossIncome: manualIncomeAsNumber })
    updateDataAndContinue(manualIncomeAsNumber)
  }

  const isIncomeInformationPresent = hasUwvData(result as IncomeData)

  useEffect(() => {
    if (!showModal) {
      fireLogNavigationEvent(AvailableStep.review, ticket, 'persoon-overzicht')
      return
    }

    if (modalType === 'agreement') {
      fireLogNavigationEvent(
        AvailableStep.review,
        ticket,
        'inkomen-overeenkomst'
      )
    } else if (modalType === 'collect') {
      fireLogNavigationEvent(AvailableStep.review, ticket, 'inkomens-ophalen')
    }
  }, [showModal, modalType])

  useEffect(() => {
    // this is how we detect fetching progress
    if (activeConfig) {
      fireLogNavigationEvent(AvailableStep.review, ticket, 'gegevens-ophalen')
    }
  }, [activeConfig])

  if (activeConfig) {
    return <Query activeConfig={activeConfig} />
  }

  const uwvConfig = configs.find((c) => c.id.includes('uwv-'))
  const mbdConfig = configs.find((c) => c.id.includes('mbd-'))

  return (
    <div className="flex flex-col items-start w-full h-full">
      {failureMessage && (
        <InfoBoxText
          classNameOverride="mb-8"
          title={failureMessage.title}
          text={failureMessage.msg}
        />
      )}
      {showModal && modalType === 'agreement' && (
        <AgreementModal
          open={showModal}
          setOpen={setShowModal}
          onAccepted={acceptAndContinue}
          onCancel={() => setShowModal(false)}
        />
      )}
      {showModal && modalType === 'agreement-explanation' && (
        <Dialog
          open={showModal}
          setOpen={setShowModal}
          showCloseButton
          title={tAll('registration.agreement.title')}
          description={renderName()}
        >
          <MarkdownText text={tAll('registration.agreement.text')} />

          <TextLight>
            <Button
              title={tAll('registration.agreement.buttonOpen.title')}
              onClick={() => setModalType('agreement')}
            />
          </TextLight>
        </Dialog>
      )}
      {showModal && modalType === 'collect' && (
        <>
          {viewModel ? (
            <Title classNameOverride="mb-4">{tAll('income.check.title')}</Title>
          ) : (
            <>
              <Title classNameOverride="mb-2">
                {tAll('income.collect.title')}
              </Title>

              <p className="mb-6">{t('collect.notice.text')}</p>
            </>
          )}

          <PersonIndicator name={renderName()} />

          {uwvConfig && (
            <IncomeButton
              isAuthorized={false}
              isSuccess={uwvConfig.status === QueryStatus.Success}
              isAccepted={!!accepted}
              config={uwvConfig}
              onFetchData={() =>
                startQuery(
                  selectedPersonId,
                  selectedPersonId === householdData.personen[0].id
                )
              }
              disable={false}
            />
          )}

          {mbdConfig && (
            <IncomeButton
              isAuthorized={false}
              isSuccess={
                mbdConfig.status === QueryStatus.Success &&
                uwvConfig?.status !== QueryStatus.Failed
              }
              isAccepted={!!accepted}
              config={mbdConfig}
              onFetchData={() =>
                startQuery(
                  selectedPersonId,
                  selectedPersonId === householdData.personen[0].id
                )
              }
              disable={uwvConfig?.status !== QueryStatus.Success}
            />
          )}

          {!!viewModel && (
            <>
              {isIncomeInformationPresent ? (
                <div className="w-full mt-6">
                  <UserInfoTable
                    birthDate={viewModel.birthDate}
                    currentIncomes={viewModel.currentIncomes}
                    grossIncome={viewModel.grossIncome}
                    grossIncomeYear={viewModel.grossIncomeYear}
                    sivIncomes={viewModel.sivIncomes}
                  />

                  <Button
                    title="Volgende"
                    icon={<ChevronRightIcon />}
                    classNameOverride="mt-8"
                    onClick={onNavigate}
                  />
                </div>
              ) : (
                <AddIncomeForm viewModel={viewModel} onSave={onSave} />
              )}
            </>
          )}
        </>
      )}
      {!showModal && (
        <AllPersonsListing
          hasFailed={!!failureMessage}
          selectedPersonId={selectedPersonId}
          onNextClick={onClick}
          onPersonClick={(id) => {
            setFailureMessage(null)
            setSelectedPersonId(id)
            isAllowedToProceedById(id)
          }}
        />
      )}
    </div>
  )
}
