import React from 'react'
import clsx from 'clsx'
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '../../assets/icons'

type Props = {
	children?: React.ReactNode
	data?: JSX.Element | string | number
	title: string
	showBorder?: boolean
	showTopBorder?: boolean
}

export const TableRow: React.FC<Props> = ({
	children,
	data,
	title,
	showBorder = false,
	showTopBorder = false,
}) => {
	if (data) {
		return (
			<div
				className={clsx('py-4', {
					'border-b border-gray-200': showBorder,
					'border-t border-gray-200': showTopBorder,
				})}
			>
				<div className="w-full flex flex-row items-center justify-between">
					<p className="font-semibold text-text text-lg text-left mb-0">
						{title}
					</p>
					<span className="font-normal text-text text-lg">{data}</span>
				</div>
			</div>
		)
	}

	return (
		<Disclosure>
			<div
				className={clsx('py-4', {
					'border-b border-gray-200': showBorder,
					'border-t border-gray-200': showTopBorder,
				})}
			>
				<Disclosure.Button className="w-full flex flex-row items-center justify-between mb-0 text-gray-900">
					{({ open }) => (
						<>
							<h3 className="font-semibold text-text text-lg text-left mb-0">
								{title}
							</h3>

							<ChevronDownIcon
								color={'currentColor'}
								style={{
									transform: open ? 'rotate(180deg)' : '',
									transition: 'transform 300ms ease',
								}}
							/>
						</>
					)}
				</Disclosure.Button>

				<Disclosure.Panel
					style={{
						animationName: 'opacity',
						animationDuration: '0.750s',
					}}
				>
					{children}
				</Disclosure.Panel>
			</div>
		</Disclosure>
	)
}
