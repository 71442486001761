import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { AppContextProvider } from '../context/app-context'
import { LayoutContextProvider } from '../context/theme-context'
import { MainLayout } from '../layout/main/MainLayout'
import {
  EnergyDataPage,
  EnergyIntroPage,
  ExtensionAndAgreementPage,
  HouseholdIntroPage,
  IncomeIntroPage,
  InputPage,
  NotAvailablePage,
  StartPage,
  ResultPage,
  ReviewPage,
  FirstPage,
} from '../pages'
import DecisionPage from '../pages/result/Decision'
import { ChoosePeoplePage } from '../pages/household/ChoosePeople'
import PageWrapper from '../components/PageWrapper/PageWrapper'
import { getRoute } from '../utils/routeUtils'
import { AvailableStep } from '../utils/stepUtil'
import PasswordProtected from './PasswordProtected'

const EnergyAppWrapper = ({
  component,
}: {
  component: React.FC
}): JSX.Element => {
  const Component = component

  return (
    <LayoutContextProvider>
      <AppContextProvider>
        <MainLayout>
          <PageWrapper>
            <Component />
          </PageWrapper>
        </MainLayout>
      </AppContextProvider>
    </LayoutContextProvider>
  )
}

export const AppRoutes: React.FC = () => (
  <Routes>
    <Route
      path={getRoute(AvailableStep.registrationStart)}
      element={<EnergyAppWrapper component={StartPage} />}
    />
    <Route
      path={getRoute(AvailableStep.registrationExtension)}
      element={<EnergyAppWrapper component={ExtensionAndAgreementPage} />}
    />
    <Route
      path={getRoute(AvailableStep.incomeIntro)}
      element={<EnergyAppWrapper component={IncomeIntroPage} />}
    />
    <Route
      path={getRoute(AvailableStep.householdIntro)}
      element={<EnergyAppWrapper component={HouseholdIntroPage} />}
    />
    <Route
      path={getRoute(AvailableStep.inputHousehold)}
      element={<EnergyAppWrapper component={InputPage} />}
    />
    <Route
      path={getRoute(AvailableStep.energyIntro)}
      element={<EnergyAppWrapper component={EnergyIntroPage} />}
    />
    <Route
      path={getRoute(AvailableStep.people)}
      element={<EnergyAppWrapper component={ChoosePeoplePage} />}
    />
    <Route
      path={getRoute(AvailableStep.review)}
      element={<EnergyAppWrapper component={ReviewPage} />}
    />
    <Route
      path={getRoute(AvailableStep.energyData)}
      element={<EnergyAppWrapper component={EnergyDataPage} />}
    />
    <Route
      path={getRoute(AvailableStep.overview)}
      element={<EnergyAppWrapper component={ResultPage} />}
    />
    <Route
      path={getRoute(AvailableStep.finish)}
      element={<EnergyAppWrapper component={DecisionPage} />}
    />
    <Route
      path="/not-available"
      element={<EnergyAppWrapper component={NotAvailablePage} />}
    />
    <Route
      path={'/login'}
      element={<EnergyAppWrapper component={PasswordProtected} />}
    />
    <Route path="*" element={<EnergyAppWrapper component={FirstPage} />} />
  </Routes>
)
