import { useForm } from 'react-hook-form'
import { InferType, array, object, string } from 'yup'
import { useContext } from 'react'
import { useNavigate } from 'react-router'
import { ChevronRightIcon, WarningIcon } from '../../assets/icons'
import {
	Button,
	CheckboxControlled,
	InfoBoxText,
	TextLight,
	Title,
} from '../../components'
import { AppContext } from '../../context/app-context'
import { useDisableBackButton } from '../../hooks/useDisableBackButton'
import { useTitle } from '../../hooks/useTitle'
import RedirectFirstPage from '../../routes/RedirectFirstPage'
import { TypeRelatie } from '../../types/api'
import { tContext } from '../../utils/cmsTranslations'
import { AvailableStep, useSetActiveStep } from '../../utils/stepUtil'
import { yupResolver } from '@hookform/resolvers/yup'
import { getRoute } from '../../utils/routeUtils'

const t = tContext('household.determine')

const choosePeopleSchema = object({
	chosenPeople: array()
		.of(string().required())
		.test('has-at-least-one', 'Vink op zijn minst een persoon aan.', (val) => {
			return val?.some((v) => v !== 'false')
		})
		.test('has-max-five', 'Maximaal 5 personen toegestaan.', (val) => {
			return !!val && val.filter((v) => v !== 'false').length < 6
		})
		.required(),
})

type ChoosePeopleFields = InferType<typeof choosePeopleSchema>

const requiredRelationTypes: TypeRelatie[] = [
	'zelf',
	'partner',
	'toeslagpartner',
]

export const ChoosePeoplePage = () => {
	useTitle('Selecteer personen')
	useDisableBackButton()
	useSetActiveStep(AvailableStep.people)

	const appCtx = useContext(AppContext)
	const navigate = useNavigate()
	const { householdData, setHouseholdData } = appCtx

	const initiallyChosenPeople = (
		householdData.backupPersonen
			? householdData.backupPersonen
			: householdData.personen.filter((p) =>
					requiredRelationTypes.includes(p.typeRelatie)
			  )
	).map((p) => p.id)

	const { register, setValue, getValues, handleSubmit, formState } =
		useForm<ChoosePeopleFields>({
			mode: 'onChange',
			resolver: yupResolver(choosePeopleSchema),
			defaultValues: {
				chosenPeople: initiallyChosenPeople,
			},
		})

	const chosenPeople = getValues('chosenPeople')

	const onSubmit = () => {
		const updatedPersonen = (
			householdData.backupPersonen || householdData.personen
		).filter((p) => {
			return (
				requiredRelationTypes.includes(p.typeRelatie) ||
				chosenPeople?.includes(p.id)
			)
		})

		const updatedHousehold = {
			...householdData,
			backupPersonen: householdData.backupPersonen || householdData.personen,
			personen: updatedPersonen,
		}

		setHouseholdData(updatedHousehold)

		navigate(getRoute(AvailableStep.incomeIntro), {
			replace: true,
		})
	}

	const hasChosenPeopleError = !!formState.errors.chosenPeople?.root?.message

	return (
		<RedirectFirstPage>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Title classNameOverride="mb-4">{t('title')}</Title>

				<TextLight classNameOverride="mb-5">{t('text')}</TextLight>

				<InfoBoxText
					title={t('notice.title')}
					text={t('notice.text')}
					classNameOverride="mb-8"
				/>

				<div className="w-full mb-8">
					{(householdData.backupPersonen || householdData.personen)?.map(
						(person, idx) => (
							<div
								key={person.id}
								className="w-full flex flex-row items-center justify-start mb-[25px]"
							>
								{requiredRelationTypes.includes(person.typeRelatie) && (
									<input
										type="hidden"
										{...register(`chosenPeople.${idx}`)}
										value={person.volledigeNaam}
									/>
								)}

								<CheckboxControlled
									{...(hasChosenPeopleError && {
										'aria-invalid': 'true',
										'aria-describedby': 'chosenpeople-error',
									})}
									{...register(`chosenPeople.${idx}`)}
									label={person.volledigeNaam}
									value={person.id}
									disabled={requiredRelationTypes.includes(person.typeRelatie)}
									onChange={(e) => {
										setValue(
											`chosenPeople.${idx}`,
											(e.target as HTMLInputElement).checked ? person.id : '',
											{ shouldValidate: true }
										)
									}}
									checked={chosenPeople.includes(person.id)}
								/>
							</div>
						)
					)}

					{hasChosenPeopleError && (
						<span
							id="chosenpeople-error"
							className="grid text-sm mt-2 overflow-hidden w-full text-red-900 grid-cols-[32px_1fr]"
						>
							<WarningIcon
								className="text-red-900 inline h-[24px] w-[24px] mx-1"
								color="currentColor"
								aria-hidden
							/>

							<span className="block min-h-[24px] leading-6">
								{formState.errors.chosenPeople?.root?.message}
							</span>
						</span>
					)}
				</div>

				<Button
					title={t('buttonConfirm.title')}
					icon={<ChevronRightIcon />}
					buttonProps={{ type: 'submit' }}
				/>
			</form>
		</RedirectFirstPage>
	)
}
